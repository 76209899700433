<template>
    <el-dialog :model-value="visiable" width="500" :title="$t('PluginBox.title')" style="text-align: left; z-index: 200" :show-close="false"
               @open="disableScroll" @close="enableScroll"
    >

        <div>
              <p v-if="pluginStatus === 1">
                {{ $t('PluginBox.installDescription') }}
              </p>
              <p v-else-if="pluginStatus === 2">
                {{ $t('PluginBox.upgradeDescription') }}
              </p>
        </div>

        <template #footer>
            <div>
                <el-button @click="downloadPlugin" class="button-background-color"> {{ $t('PluginBox.downloadDriver') }}</el-button>
                <el-button :onclick="onClick" class="button-background-color"> {{ $t('PluginBox.confirmButton') }}</el-button>
            </div>
        </template>

    </el-dialog>
</template>


<script setup>

import {defineProps, onMounted, onUnmounted} from 'vue'
import EventBus from "@/eventBus";
import dialogMixin from "@/mixins/dialogMixin";

const props = defineProps({
    visiable: {
        type: Boolean,
        default: false
    },
    pluginStatus: {
      type: Number,
      default: 0,
    },
    pluginUrl: {
        type: String,
        default: '',
    },
    handleClose: {
        type: Function,
        required: true
    }
})
// 新增下载插件的方法
function downloadPlugin() {
  if (props.pluginUrl) {
    const link = document.createElement('a');
    link.href = props.pluginUrl;
    link.setAttribute('download', ''); // 如果需要可以设置下载的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    props.handleClose(); // 下载后关闭对话框
  }
}

function onClick() {
    props.handleClose();
}

onMounted(() => {
  EventBus.on('show-plugin-box', () => {
  });
});

onUnmounted(() => {
  EventBus.off('show-plugin-box');
});

// 将 mixin 的方法定义到组件中
const { disableScroll, enableScroll } = dialogMixin.methods;
</script>