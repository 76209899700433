<template>
  <div class="settings-page">
    <header style="margin-bottom: 20px;">
      <el-icon @click="goBack">
        <Back/>
      </el-icon>&nbsp;&nbsp;<b>{{ $t('settingPage.settings') }}</b>
    </header>

    <section>
      <div class="setting">
        <span class="setting-label">{{ $t('settingPage.username') }}:</span>
        <span class="setting-value">{{ username }}</span>
      </div>

      <div class="setting">
        <span class="setting-label">{{ $t('settingPage.password') }}:</span>
        <span class="setting-value">{{ showPassword ? '********' : '******' }}</span>
        <el-icon @click="editPassword">
          <EditPen/>
        </el-icon>
      </div>

<!--      <div class="setting">
        <span class="setting-label">{{ $t('settingPage.language') }}:</span>
        <el-select v-model="selectedLanguage" :placeholder="$t('settingPage.selectLanguage')" filterable
                   style="width: 150px;">
          <el-option v-for="(code,friendlyName) in languageMapping" :key="code" :label="friendlyName" :value="code">
          </el-option>
        </el-select>
      </div>-->
    </section>

    <!-- 编辑密码弹框 -->
    <el-dialog :title="$t('settingPage.changePassword')" v-model="dialogVisible" width="500"
               style="text-align: left;" @open="handleDialogOpen" @close="handleDialogClose">

      <hr class="top-separator"/>

      <el-form :model="passwordForm" ref="passwordForm" :rules="passwordRules" label-position="top">
        <el-form-item :label="$t('settingPage.oldPassword')" prop="oldPassword" label-width="100px"
                      style="margin-bottom: 30px;">
          <el-input v-model="passwordForm.oldPassword" :placeholder="$t('settingPage.enterOldPassword')" type="password"
                    :show-password="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('settingPage.newPassword')" prop="newPassword" label-width="100px"
                      style="margin-bottom: 30px;">
          <el-input v-model="passwordForm.newPassword" :placeholder="$t('settingPage.enterNewPassword')" type="password"
                    :show-password="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('settingPage.confirmNewPassword')" prop="confirmPassword" label-width="100px"
                      style="margin-bottom: 30px;">
          <el-input v-model="passwordForm.confirmPassword" :placeholder="$t('settingPage.enterConfirmPassword')"
                    type="password"
                    :show-password="true"></el-input>
        </el-form-item>
      </el-form>

      <hr class="dialog-separator"/>

      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" class="custom-reset-button">{{ $t('settingPage.cancel') }}</el-button>
        <el-button class="button-background-color" @click="savePassword">{{ $t('settingPage.confirm') }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {Back, EditPen} from "@element-plus/icons-vue";
import mitt from 'mitt';
import dialogMixin from "@/mixins/dialogMixin";
import {mapActions, mapGetters} from "vuex";
import {changePassword} from "@/api/api";
import router from "@/router";

const emitter = mitt();

export default {
  components: {Back, EditPen},
  data() {
    return {
      username: '',
      showPassword: false,
      dialogVisible: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      passwordRules: {
        oldPassword: [
          {required: true, message: this.$t('settingPage.enterOldPassword'), trigger: 'blur'},
        ],
        newPassword: [
          {required: true, message: this.$t('settingPage.enterNewPassword'), trigger: 'blur'},
          {
            min: 8,
            max: 20,
            message: this.$t('settingPage.passwordLength'),
            trigger: 'blur',
          },
          {
            validator: this.validatePassword,
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {required: true, message: this.$t('settingPage.enterConfirmPassword'), trigger: 'blur'},
          {
            validator: this.validateConfirmPassword,
            trigger: 'blur',
          },
        ],
      },
      languageMapping: {
        '简体中文': 'zh',
        'English': 'en',
        'Italiano': 'it',
        'Francais': 'fr',
        'Deutsch': 'de',
        'Dutch': 'nl',
        '日本語': 'ja',
        'Svenska': 'sv',
        'Magyar': 'hu',
        'Polski': 'pl',
        'Español': 'es',      // 添加西班牙语
        'Português': 'pt'     // 添加葡萄牙语
      },
    };
  },
  computed: {
    ...mapGetters(['currentLanguage', 'currentLanguageFriendlyName']),
    selectedLanguage: {
      get() {
        return this.currentLanguage;
      },
      set(value) {
        // 这里避免直接调用 changeLanguage 方法
        this.$store.dispatch('changeLanguage', {language: value, friendlyName: this.getFriendlyName(value)});
      }
    }
  },
  watch: {
    selectedLanguage(newVal) {
      this.$i18n.locale = newVal;
      emitter.emit('changeLanguage', newVal);
    }
  },
  mixins: [dialogMixin],
  mounted() {
    // 从LocalStorage中获取用户名和密码
    const storedUsername = localStorage.getItem('user_email');
    if (storedUsername) {
      this.username = storedUsername;
    }
    // 这里你可以根据需要处理密码的显示逻辑
  },
  methods: {
    ...mapActions(['changeLanguage']),
    handleDialogClose() {
      this.resetForm();
      this.enableScroll();
    },
    handleDialogOpen() {
      this.resetForm();
      this.disableScroll();
    },
    goBack() {
      // 使用 Vue Router 返回上一个页面
      this.$router.go(-1); // 或者使用 this.$router.back();
    },
    // 弹出编辑密码弹框
    editPassword() {
      this.dialogVisible = true;
      // 清空密码表单
      this.passwordForm = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      };
    },

    // 重置密码表单
    resetForm() {
      this.$refs.passwordForm.resetFields();
    },

    // 保存密码
    async savePassword() {
      await this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          try {
            await changePassword({
              current_password: this.passwordForm.oldPassword,
              new_password: this.passwordForm.newPassword,
            });
            this.$message.success(this.$t('settingPage.passwordUpdated'));
            this.dialogVisible = false;
            await router.push('/LoginPage');
          } catch (error) {
            this.$message.error(this.$t('settingPage.passwordUpdateError'));
          }
        }
      });
    },

    // 验证新密码的复杂性
    validatePassword(rule, value, callback) {
      // 定义正则表达式来检查密码复杂性
      const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=!]).{8,20}$/;

      if (regex.test(value)) {
        callback(); // 密码符合复杂性要求
      } else {
        callback(this.$t('settingPage.passwordComplexityErrorMessage'));
      }
    },

    // 验证确认新密码与新密码是否一致
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.passwordForm.newPassword) {
        callback(this.$t('settingPage.passwordMismatch'));
      } else {
        callback();
      }
    },
    changeLanguage({language, friendlyName}) {
      this.$store.dispatch('changeLanguage', {language, friendlyName});
      this.$i18n.locale = language;  // 同步 i18n 语言
    },
    getFriendlyName(languageCode) {
      return Object.keys(this.languageMapping).find(key => this.languageMapping[key] === languageCode);
    }
  },
};
</script>

<style scoped>
/* 样式可以根据你的需求进行自定义 */
.settings-page {
  padding: 20px;
}

header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.setting {
  display: flex;
  align-items: center;
  margin: 10px 0 20px 0;
}

.setting-label {
  min-width: 100px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {

  /* 响应式样式，适应小屏幕 */
  .settings-page {
    padding: 10px;
  }
}
</style>
